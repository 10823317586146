import React from "react";
import {
  //AboutSection,
  //ArticlesSection,
  ContactSection,
  //HeroSection,
  //InterestsSection,
  Page,
  ProjectsSection,
  Seo,
} from "gatsby-theme-portfolio-minimal";

export default function IndexPage() {

  const subheading = <>These are some general-purpose A-Frame components that I have built, or that I maintain.  All open source.</>

  return (
    <>
      <Seo title="Diarmid Mackenzie" />
      <Page>
        {/*<HeroSection sectionId="hero" />*/}
        {/*<ArticlesSection sectionId="articles" heading="Latest Articles" sources={['Medium']} />*/}
        {/*<AboutSection sectionId="about" heading="About Me" />*/}
        {/*<InterestsSection sectionId="interests" heading="Interests" />*/}
        <ProjectsSection sectionId="projects" heading="A-Frame Components" subheading={subheading} section={2}/>
        <ContactSection sectionId="github" heading="Contact" />
      </Page>
    </>
  );
}
