import React from "react";
import {
  //AboutSection,
  //ArticlesSection,
  ContactSection,
  //HeroSection,
  //InterestsSection,
  Page,
  ProjectsSection,
  Seo,
} from "gatsby-theme-portfolio-minimal";

export default function IndexPage() {

  const subheading = <>
    This page details some of the side projects that I have built over the last few years.
    <br/><br/>

    Some of these have been published and launched with some success.  Others remain at an early stage, and may never be finished.
    <br/><br/>

    If you have an interest in any of these, please let me know.  There are some that I would be happy to have an excuse to revisit.
    </>

  return (
    <>
      <Seo title="Diarmid Mackenzie" />
      <Page>
        {/*<HeroSection sectionId="hero" />*/}
        {/*<ArticlesSection sectionId="articles" heading="Latest Articles" sources={['Medium']} />*/}
        {/*<AboutSection sectionId="about" heading="About Me" />*/}
        {/*<InterestsSection sectionId="interests" heading="Interests" />*/}
        <ProjectsSection sectionId="projects" heading="Side Projects" subheading={subheading} section={1}/>
        <ContactSection sectionId="github" heading="Contact" />
      </Page>
    </>
  );
}
