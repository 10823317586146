// extracted by mini-css-extract-plugin
export var Article = "style-module--Article--fc6f5";
export var BackArrow = "style-module--BackArrow--25d95";
export var Banner = "style-module--Banner--bf20b";
export var BannerCaption = "style-module--BannerCaption--f10fe";
export var BannerImage = "style-module--BannerImage--b20ad";
export var Body = "style-module--Body--49075";
export var Breadcrumb = "style-module--Breadcrumb--46583";
export var Category = "style-module--Category--6f78f";
export var Content = "style-module--Content--419f3";
export var Details = "style-module--Details--1c29d";
export var Footer = "style-module--Footer--9ab07";
export var Header = "style-module--Header--8628b";
export var Keyword = "style-module--Keyword--8d39d";
export var ReadingTime = "style-module--ReadingTime--fb17f";